import Vue from 'vue'
import TurbolinksAdapter from 'vue-turbolinks'
import Buefy from 'buefy'
import _ from 'lodash'
import axios from 'axios'
import 'element-closest'
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import 'buefy/dist/buefy.css'
import '../css/common.scss'

Vue.use Buefy,
  defaultIconPack: 'fa'
Vue.use TurbolinksAdapter

import Notifications from '../components/notifications.vue'
import SegmentTable from '../components/segment_table.vue'
import Progress from '../components/bulma_progress.vue'
import AdminFileTable from '../components/admin_file_table.vue'
import AdminRefCsvTable from '../components/admin_ref_csv_table.vue'
import AdminRefFileTable from '../components/admin_ref_file_table.vue'
import AdminIntegratedRefFileTable from '../components/admin_integrated_ref_file_table.vue'

vcs = {
  Notifications,
  SegmentTable,
  Progress,
  AdminFileTable,
  AdminRefCsvTable,
  AdminRefFileTable,
  AdminIntegratedRefFileTable,
}

window._vms = vms = {}

window.addEventListener 'DOMContentLoaded', ->
  token = document.getElementsByName('csrf-token')[0].getAttribute('content')
  axios.defaults.headers.common['X-CSRF-Token'] = token
  axios.defaults.headers.common['Accept'] = 'application/json'

document.addEventListener 'turbolinks:load', ->
  for el in document.querySelectorAll('[data-vue]')
    continue unless vcs
    v = _.cloneDeep vcs[el.dataset.vue]
    unless v
      if console && console.error
        console.error "Cannot find Vue component: " + el.dataset.vue
      continue
    v.el = el
    data = null
    if el.dataset.vueData
      data = JSON.parse el.dataset.vueData
    if el.id && !data['id']
      data = {} unless data
      data.id = el.id
    if data
      if v['props']
        v.propsData = data
      else
        v.data = (-> data)
    if process.env.NODE_ENV == 'development'
      console.debug "Create vue intance #{el.dataset.vue}:", v
    vm = new Vue (v)
    if el.id
      vms[_.camelCase(el.id)] = vm

document.addEventListener 'turbolinks:visit', ->
  for id in Object.keys(vms)
    delete vms[id]
