import { render, staticRenderFns } from "./admin_file_table.vue?vue&type=template&id=0a220995&scoped=true&lang=pug&"
import script from "./admin_file_table.vue?vue&type=script&lang=coffee&"
export * from "./admin_file_table.vue?vue&type=script&lang=coffee&"
import style0 from "./admin_file_table.vue?vue&type=style&index=0&id=0a220995&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a220995",
  null
  
)

export default component.exports