








































import axios from 'axios'
import _ from 'lodash'
import Progress from '../components/bulma_progress.vue'

export default
  props:
    id: String
    integrated_ref_files_url:
      type: String
      required: true
    integrated_ref_files:
      type: Array
      default: (-> [])
  computed:
    I18n: ->
      window.I18n
  components:
    'b-progress': Progress
  created: ->
    this.reload()
  methods:
    reload: ->
      axios.get(this.integrated_ref_files_url).then (resp) =>
        if resp.status == 200
          this.integrated_ref_files = resp.data
          _.each this.integrated_ref_files, (file, idx) =>
            return if !file.is_ref_item_dirty
            this.refresh_refs_by_idx idx
    refresh_all_refs: ->
      _.each this.integrated_ref_files, (file, idx) =>
        this.refresh_refs_by_idx idx
    refresh_refs_by_idx: (idx) ->
      file = this.integrated_ref_files[idx]
      return false if file.is_ref_item_update_in_progress
      file.is_ref_item_update_in_progress = true
      this.integrated_ref_files.splice idx, 1, file
      req = axios.post(file.url_refresh_refs)
      req.then (resp) =>
        this.reload()
        _vms.notifications.add_msg 'info', "#{resp.data.integrated_ref_file.name}に #{resp.data.count}件の参照定義を作成しました"
      req.catch (error) =>
        console.log(error)
        file.error = true
        this.integrated_ref_files.splice idx, 1, file
    delete_confirm: (file) ->
      this.$buefy.dialog.confirm
        scroll: 'keep'
        message: "本当に #{_.escape file.name} を削除してよろしいですか？<br />この操作は取り消せません"
        onConfirm: =>
          axios.delete(file.url).then (resp) =>
            this.reload()
