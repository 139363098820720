
















































import axios from 'axios'
import _ from 'lodash'
import Progress from '../components/bulma_progress.vue'

markInsertTarget = (root, target) ->
  for t in $$('tr.insert-target', root)
    t.classList.remove 'insert-target'
  return unless target?
  if target.nodeName == '#text'
    target = target.parentNode
  unless target.nodeName == 'TR'
    target = target.closest 'tr'
  return unless target
  target.classList.add 'insert-target'

export default
  props:
    id: String
    xml_files_url:
      type: String
      required: true
    xml_files:
      type: Array
      default: (-> [])
  computed:
    I18n: ->
      window.I18n
  components:
    'b-progress': Progress
  created: ->
    this.reload()
  data: (-> {fileDragging: null})
  methods:
    reload: ->
      axios.get(this.xml_files_url).then (resp) =>
        if resp.status == 200
          this.xml_files = resp.data
          _.each this.xml_files, (file, idx) =>
            return if !file.is_segment_dirty
            this.refresh_file_ts_by_idx idx
    refresh_all_ts: ->
      _.each this.xml_files, (file, idx) =>
        this.refresh_file_ts_by_idx idx
    refresh_file_ts_by_idx: (idx) ->
      file = this.xml_files[idx]
      return false if file.is_segment_update_in_progress
      file.is_segment_update_in_progress = true
      this.xml_files.splice idx, 1, file
      req = axios.post(file.url_refresh_ts)
      req.then (resp) =>
        this.reload()
        _vms.notifications.add_msg 'info', "#{resp.data.xml_file.name}に #{resp.data.count}件のテキストセグメントを作成しました"
      req.catch (error) =>
        console.log(error)
        file.error = true
        this.xml_files.splice idx, 1, file
    delete_confirm: (file) ->
      this.$buefy.dialog.confirm
        scroll: 'keep'
        message: "本当に #{_.escape file.name} を削除してよろしいですか？<br />この操作は取り消せません"
        onConfirm: =>
          axios.delete(file.url).then (resp) =>
            this.reload()
    dragStart: (file, idx, ev)->
      this.fileDragging = file
      file.dragging = true
      this.xml_files.splice idx, 1, file
      ev.dataTransfer.setData('text/plain', file.id.$oid)
    dragEnter: (file, idx, ev) ->
      if this.fileDragging && !file.is_minor
        markInsertTarget this.$el, ev.target
    dragEnd: (file, idx, ev) ->
      markInsertTarget this.$el
      file.dragging = false
      this.xml_files.splice idx, 1, file
    drop: (file, ev) ->
      src = this.fileDragging
      axios.patch(src.url, {xml_file: {position: file.position}}).then((resp) =>
        if resp.status >= 200 && resp.status < 300
          this.reload()
      ).catch((err) =>
        msg = 'サーバでエラーが発生しました'
        if err.response.status == 422 && err.response.data.errors
          for key, ms of err.response.data.errors
            msg += "\n#{key}: #{Array(ms).join("\n")}"
        alert msg
      )
      this.fileDragging = false

