







































import axios from 'axios'
import _ from 'lodash'
import Progress from '../components/bulma_progress.vue'

export default
  props:
    id: String
    ref_def_csvs_url:
      type: String
      required: true
    ref_def_csvs:
      type: Array
      default: (-> [])
  computed:
    I18n: ->
      window.I18n
  components:
    'b-progress': Progress
  created: ->
    this.reload()
  methods:
    reload: ->
      axios.get(this.ref_def_csvs_url).then (resp) =>
        if resp.status == 200
          this.ref_def_csvs = resp.data
          _.each this.ref_def_csvs, (file, idx) =>
            return if !file.is_refdef_dirty
            this.refresh_refs_by_idx idx
    refresh_all_refs: ->
      _.each this.ref_def_csvs, (file, idx) =>
        this.refresh_refs_by_idx idx
    refresh_refs_by_idx: (idx) ->
      file = this.ref_def_csvs[idx]
      return false if file.is_refdef_update_in_progress
      file.is_refdef_update_in_progress = true
      this.ref_def_csvs.splice idx, 1, file
      req = axios.post(file.url_refresh_refs)
      req.then (resp) =>
        this.reload()
        _vms.notifications.add_msg 'info', "#{resp.data.ref_def_csv.name}に #{resp.data.count}件の参照定義を作成しました"
      req.catch (error) =>
        console.log(error)
        file.error = true
        this.ref_def_csvs.splice idx, 1, file
    delete_confirm: (file) ->
      this.$buefy.dialog.confirm
        scroll: 'keep'
        message: "本当に #{_.escape file.name} を削除してよろしいですか？<br />この操作は取り消せません"
        onConfirm: =>
          axios.delete(file.url).then (resp) =>
            this.reload()
