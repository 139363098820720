









































import axios from 'axios'
import _ from 'lodash'
import Progress from '../components/bulma_progress.vue'

markInsertTarget = (root, target) ->
  for t in $$('tr.insert-target', root)
    t.classList.remove 'insert-target'
  return unless target?
  if target.nodeName == '#text'
    target = target.parentNode
  unless target.nodeName == 'TR'
    target = target.closest 'tr'
  return unless target
  target.classList.add 'insert-target'

export default
  props:
    id: String
    ref_files_url:
      type: String
      required: true
    ref_files:
      type: Array
      default: (-> [])
  computed:
    I18n: ->
      window.I18n
  components:
    'b-progress': Progress
  created: ->
    this.reload()
  methods:
    reload: ->
      axios.get(this.ref_files_url).then (resp) =>
        if resp.status == 200
          this.ref_files = resp.data
          _.each this.ref_files, (file, idx) =>
            return if !file.is_segment_dirty
            this.refresh_ref_segment_by_idx idx
    refresh_all_segments: ->
      _.each this.ref_files, (file, idx) =>
        this.refresh_ref_segment_by_idx idx
    refresh_ref_segment_by_idx: (idx) ->
      file = this.ref_files[idx]
      return false if file.is_segment_update_in_progress
      file.is_segment_update_in_progress = true
      this.ref_files.splice idx, 1, file
      req = axios.post(file.url_refresh_segments)
      req.then (resp) =>
        this.reload()
        _vms.notifications.add_msg 'info', "#{resp.data.ref_file.name}に #{resp.data.count}件の参照セグメントを作成しました"
      req.catch (error) =>
        console.log(error)
        file.error = true
        this.ref_files.splice idx, 1, file
    delete_confirm: (file) ->
      this.$buefy.dialog.confirm
        scroll: 'keep'
        message: "本当に #{_.escape file.name} を削除してよろしいですか？<br />この操作は取り消せません"
        onConfirm: =>
          axios.delete(file.url).then (resp) =>
            this.reload()
