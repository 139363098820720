










prop_def = ->
  type: Array
  default: (-> [])
export default
  props:
    id: String
    error: prop_def()
    alert: prop_def()
    warning: prop_def()
    notice: prop_def()
    info: prop_def()
    debug: prop_def()
  methods:
    add_msg: (key, msg) ->
      switch key
        when "error"   then this.error.push msg
        when "alert"   then this.alert.push msg
        when "warning" then this.warning.push msg
        when "warn"    then this.warning.push msg
        when "notice"  then this.notice.push msg
        when "info"    then this.info.push msg
        when "debug"   then this.debug.push msg
