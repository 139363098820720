




import _ from 'lodash'
export default
  props:
    id:
     type: String
     required: true
    className: String
    progressId:
      type: String
      required: true
  data: (-> {max: 100, value: 0})
  created: ->
    if window._vms?
      window._vms[_.camelCase(this.id)] = this
