










































































import axios from 'axios'
import _ from 'lodash'
export default
  props:
    id: String
    doc_url:
      type: String
      required: true
    text_query_url:
      type: String
      required: true
    isComponentModalActive:
      type: Boolean
      default: false
    isDebug:
      type: Boolean
      default: process? && process.env? && process.env.NODE_ENV == 'development'
  data: ->
      isFirstLoad: true
      isLoading: false
      isAPIError: {}
      curRow: {}
      curLength: 0
      curIdx: 0
      doc:
        revisions: []
        segments_table: []
      texts: {}
      I18n: window.I18n
  computed:
    curRevision: ->
      idx = 0
      for r in this.doc.revisions
        continue if !this.curRow[r._id.$oid]
        rlen = this.curRow[r._id.$oid].length
        return r if idx + rlen > this.curIdx
        idx += rlen
      return if idx >= this.curIdx && r then r else {}
    curSegments: ->
      idx = 0
      for r in this.doc.revisions
        for s in (this.curRow[r._id.$oid] || [])
          return s if idx == this.curIdx
          idx += 1
      return []
  created: ->
    this.isLoading = true
    axios.get(this.doc_url).then((resp) =>
      if resp.status == 200
        this.doc = resp.data
    ).catch((error) =>
      this.$set this.isAPIError, 'table', true
    ).then =>
      this.isLoading = false
  updated: ->
    if this.isFirstLoad && location.hash && location.hash.match(/^#sid:/) && el = document.getElementById(location.hash.substr(1))
      this.isFirstLoad = false
      el.scrollIntoView()
      window.scrollBy 0, -$('thead').clientHeight
  methods:
    showBody: (row, rev, s_idx) ->
      sids = []
      tids = []
      for r, c of row
        for rc in c
          for sd in rc
            if sd && sd.fname && sd.tls
              sids.push "#{sd.fname}:#{sd.tls}"
            if sd.tid
              tids.push sd.tid
      axios.post(this.text_query_url, {q: {ids: tids}}).then((resp) =>
        if resp.status == 200
          this.texts = resp.data
          this.$set this.isAPIError, 'text', false
      ).catch((error) =>
        this.$set this.isAPIError, 'text', true
      )
      this.curRow = row
      this.curIdx = 0
      for r in this.doc.revisions
        for s, si in (row[r._id.$oid] || [])
          break if r._id.$oid == rev._id.$oid && si == s_idx
          this.curIdx += 1
        break if r._id.$oid == rev._id.$oid
      this.curLength = 0
      for r in this.doc.revisions
        this.curLength += (row[r._id.$oid] || []).length
      this.isComponentModalActive = true
    classForSegs: (sds) ->
      _.chain(sds).flatten().map('type').compact().uniq().join(' ').value()
    selectRefFiles: (crefs) ->
      _.filter(crefs, (ref) => ref.ref_fname || ref.fid)
    curText: (smt) ->
      return {} if !this.texts || !smt.tid
      this.texts[smt.tid] || {}
    curRefs: (smt) ->
      this.curText(smt).refs || {}
